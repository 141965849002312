<div class="grid">
  <div class="col-12 mb-1 flex justify-content-center">
    <p-button *ngIf="!isEditingMode" (click)="addNewWhereabout()">
      <i class="pi pi-plus mr-1"></i>
      {{ 'animalWhereabout.addWhereabout' | transloco }}
    </p-button>
  </div>
</div>
<ng-container *ngFor="let animalWhereaboutsItem of animalWhereaboutsItems | keyvalue: dummyFirst">
  <div class="card mt-2 details-view"
    *ngIf="openTab['all'] || openTab['whereabout'] === animalWhereaboutsItem.key || !isEditingMode"
  >
    <ng-container *ngTemplateOutlet="animalWhereaboutsFormTemplate; context: { key: animalWhereaboutsItem.key }"></ng-container>
    <div class="col-12" *ngIf="newForm">
      <div class="flex justify-content-end">
        <p-button [label]="'modal.cancel' | transloco" class="p-button-secondary" (click)="cancelNewWhereabout()"></p-button>
        <p-button [label]="'modal.save' | transloco" class="ml-2 p-button-secondary" (click)="saveNewWhereabout()"></p-button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #animalWhereaboutsFormTemplate let-key="key">
  <ng-container *ngIf="formGroupWhereabouts[key] || formGroupWhereabouts['dummy']">
    <form [formGroup]="formGroupWhereabouts[key]">
      <div class="grid">
        <div class="col-12" *ngIf="!isEditingMode">
          <div class="flex">
            <div class="flex-1 p-4 text-xl font-semibold">
              <span *ngIf="newForm">{{ 'animalWhereabout.new' | transloco }} </span>
              {{ 'animalWhereabout.general.headline' | transloco }} <span *ngIf="!newForm">|</span>
              {{ animalWhereaboutsItems[key].contact.firstName }}
              {{ animalWhereaboutsItems[key].contact.lastName }}
              {{ animalWhereaboutsItems[key].date | date : 'dd.MM.yyyy' }}
            </div>
            <span class="flex-end" *ngIf="!openEdit">
              <button class="p-button p-button-sm" (click)="toggleEditDialog(true, key)">
                <i class="pi pi-pencil mr-1"></i>
                {{ 'edit' | transloco }}
              </button>
            </span>
          </div>
        </div>
        <div class="col-12">
          <p-accordion [multiple]="true" [activeIndex]="[0]">
            <p-accordionTab header="{{ 'animalDetails.general.headline' | transloco }}">
              <ng-template pTemplate="content">
                <div class="grid">
                  <div class="col-4">
                    <label class="py-1" for="type">{{ 'animalWhereabout.type' | transloco }}</label>
                    <p-dropdown
                      class="p-inputdropdown-sm w-full"
                      id="type"
                      [options]="whereAboutTypeOptions"
                      optionLabel="name"
                      [autoDisplayFirst]="false"
                      formControlName="type"
                    >
                    </p-dropdown>
                  </div>
                  <div class="col-4">
                    <label class="py-1" for="date">{{ 'animalWhereabout.date' | transloco }}</label>
                    <p-calendar
                      class="w-full p-inputcalendar-sm"
                      id="date"
                      formControlName="date"
                      dateFormat="dd.mm.yy"
                      [showIcon]="true"
                      appendTo="body"
                    ></p-calendar>
                  </div>
                  <div class="col-4">
                    <label class="py-1" for="date">{{ 'animalWhereabout.dateCare' | transloco }}</label>
                    <p-calendar class="w-full p-inputcalendar-sm" id="dateCare"
                      formControlName="dateCare"
                      dateFormat="dd.mm.yy"
                      [showIcon]="true"
                      appendTo="body"
                    ></p-calendar>
                  </div>
                  <div class="col-12" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].information">
                    <label class="py-1" for="information">{{ 'animalWhereabout.information' | transloco }}</label>
                    <textarea
                      pInputText
                      area
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="p-inputtext-sm w-full"
                      formControlName="information"
                      id="information"
                      rows="5"
                    ></textarea>
                  </div>
                  <div class="col-12" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].stillOnWebsite">
                    <label for="stillOnWebsite" class="py-1">{{ 'animalWhereabout.labelStillOnWebsite' | transloco}}</label>
                    <p-toggleButton
                        formControlName="stillOnWebsite"
                        id="stillOnWebsite"
                        onLabel="{{ 'yes' | transloco }}" 
                        offLabel="{{ 'no' | transloco }}" />
                  </div>
                  <div class="col-12 mt-3" *ngIf="contactInfosExist[key]">
                      <span class="section-header font-semibold">{{ 'animalAdd.origin.transferBy' | transloco }}</span>
                  </div>
                  <div class="col-3" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.gender">
                    <label class="py-0" for="personSalutation">{{ 'animalWhereabout.person.salutation' | transloco }}</label>
                    <p-dropdown class="p-inputdropdown-sm w-full" id="personSalutation"
                      [options]="personSalutationOptions"
                      optionLabel="name"
                      optionValue="value"
                      formControlName="personSalutation">
                    </p-dropdown>
                  </div>
                  <div class="col-4" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.lastName">
                    <label class="py-1" for="personLastName">{{ 'animalWhereabout.person.lastName' | transloco }}</label>
                    <input
                      id="personLastName"
                      type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personLastName"
                    />
                  </div>
                  <div class="col-5" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.firstName">
                    <label class="py-1" for="personFirstName">{{ 'animalWhereabout.person.firstName' | transloco }}</label>
                    <input
                      id="personFirstName"
                      type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personFirstName"
                    />
                  </div>
                  <div class="col-12" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.companyName">
                    <label class="py-1" for="personCompanyName">{{ 'animalWhereabout.person.companyName' | transloco }}</label>
                    <input id="personCompanyName" type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personCompanyName"
                    />
                  </div>
                  <div class="col-6" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.street">
                    <label class="py-1" for="personStreet">{{ 'animalWhereabout.person.street' | transloco }}</label>
                    <input
                      id="personStreet"
                      type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personStreet"
                    />
                  </div>
                  <div class="col-2" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.zipCode">
                    <label class="py-1" for="personZipCode">{{ 'animalWhereabout.person.zipCode' | transloco }}</label>
                    <input
                      id="personZipCode"
                      type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personZipCode"
                    />
                  </div>
                  <div class="col-4" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.city">
                    <label class="py-1" for="personCity">{{ 'animalWhereabout.person.city' | transloco }}</label>
                    <input
                      id="personCity"
                      type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personCity"
                    />
                  </div>
                  <div class="col-6" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.mobilePhoneNumber">
                    <label class="py-1" for="personMobilePhoneNumber">{{ 'animalWhereabout.person.mobilePhoneNumber' | transloco }}</label>
                    <input
                      id="personMobilePhoneNumber"
                      type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personMobilePhoneNumber"
                    />
                  </div>
                  <div class="col-6" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.phoneNumber">
                    <label class="py-1" for="personPhoneNumber">{{ 'animalWhereabout.person.phoneNumber' | transloco }}</label>
                    <input
                      id="personPhoneNumber"
                      type="text"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      class="w-full p-inputtext-sm"
                      pInputText
                      formControlName="personPhoneNumber"
                    />
                  </div>
                  <div class="col-6" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.email">
                    <label class="py-1" for="personEmail">{{ 'animalWhereabout.person.email' | transloco }}</label>
                    <div class="p-inputgroup">
                      <span class="p-inputgroup-addon" [pTooltip]="'animalWhereabout.person.email' | transloco" tooltipPosition="top">
                        <a class="absolute" [href]="'mailto:' + animalWhereaboutsItems[key].contact.email" *ngIf="animalWhereaboutsItems[key].contact.email">
                          <i class="pi pi-envelope"></i>
                        </a>
                      </span>
                      <input
                        id="personEmail"
                        type="text"
                        [class.no-border]="!isEditingMode && !isAddMode"
                        class="w-full p-inputtext-sm"
                        pInputText
                        formControlName="personEmail"
                      />
                    </div>
                  </div>
                  <div class="col-6" *ngIf="isAddMode || isEditingMode || animalWhereaboutsItems[key].contact.idCardNumber">
                    <label class="py-1" for="personIdCardNumber">{{ 'animalWhereabout.person.idCardNumber' | transloco }}</label>
                    <input
                      type="text"
                      class="w-full p-inputtext-sm"
                      [class.no-border]="!isEditingMode && !isAddMode"
                      formControlName="personIdCardNumber"
                      id="personIdCardNumber"
                      pInputText
                    />
                  </div>
                </div>
              </ng-template>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>

<p-dialog
    [(visible)]="openEdit"
    [style]="{ width: '90vw', height: '90vh' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    (onHide)="toggleEditDialog(false , editingWhereAboutKey)"
>
  <ng-template pTemplate="closeicon">
    <span class="closeicon pi pi-times"></span>
  </ng-template>
  <ng-template pTemplate="header">
    <div class="flex align-items-center mb-3">
      <div class="px-4">
        <app-animal-current-category
            [animal]="animal"
        ></app-animal-current-category>
      </div>
      <div class="flex px-4 animal-name-headline">
        {{ animal?.displayName }}
      </div>
      <div class="flex px-4 animal-key">
        <img src="./assets/images/icon_key_green.svg" alt="key"/>
        <p>{{ animal?.bookNumber }} <span *ngIf="animal?.oldBookNumber">({{animal?.oldBookNumber}})</span></p>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="animalWhereaboutsFormTemplate; context: { key: editingWhereAboutKey }"></ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="flex-1 text-center">
        <button class="p-button p-button-success" (click)="saveChanges()"
            [disabled]="formGroupWhereabouts[editingWhereAboutKey] && formGroupWhereabouts[editingWhereAboutKey].invalid">
          {{ 'animalEdit.general.saveChanges' | transloco }}
        </button>
      </div>
    </div>
    <div class="flex-end">
      <span class="required">* {{ 'animalEdit.general.required' | transloco }}</span>
    </div>
  </ng-template>
</p-dialog>
