import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AnimalWhereaboutModel} from '../models/animal-whereabout.model';
import {AnimalModel} from '../models/animal.model';
import {AnimalWhereaboutsRepository} from '../states/animal-whereabouts.repository';
import {AnimaleWhereAboutService} from '../services/animal-whereabout.service';
import {AnimalWhereaboutsType} from '../models/animal-wherabout-types.enum';
import {combineLatest, take, takeWhile} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {PersonTitleModel} from "../../../shared/models/person-title.model";
import {ContactRepository} from "../../contact/states/contact.repository";
import {ContactModel} from "../../contact/models/contact.model";
import {HelperService} from "../../../shared/services/helper.service";
import {AnimalRepository} from "../states/animal.repository";

@Component({
  selector: 'app-animal-details-whereabouts',
  templateUrl: './animal-details-whereabouts.component.html',
  styleUrls: ['./animal-details-whereabouts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnimalDetailsWhereaboutsComponent implements OnInit {
  @Input() animal: AnimalModel | undefined;
  @Output() formsValid: EventEmitter<boolean> = new EventEmitter();
  alive: boolean = true;
  isEditingMode: boolean = false;
  isAddMode: boolean = false;
  openTab: { [tab: string]: boolean | string } = {};
  formGroupWhereabouts: { [id: string]: FormGroup } = {};
  animalWhereaboutsItems: { [id: string]: any } = {};
  contactInfosExist: { [id: string]: boolean } = {};
  allFormsValid: boolean = false;
  whereAboutTypeOptions: { value: string, name: string }[] = [];
  newForm: boolean = false;
  openEdit: boolean = false;
  editingWhereAboutKey: string = '';
  personSalutationOptions: PersonTitleModel[] = [];

  constructor(
    private _animalWhereaboutsRepository: AnimalWhereaboutsRepository,
    private _contactRepository: ContactRepository,
    private _animalRepository: AnimalRepository,
    private _translocoService: TranslocoService,
    private _animalWhereaboutService: AnimaleWhereAboutService,
    private _helperService: HelperService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this._animalWhereaboutsRepository.getByAnimalId(this.animal?.id).pipe(takeWhile(() => this.alive)).subscribe((animalWhereabouts) => {
      if (animalWhereabouts.length) {
        this.setAnimalWhereabouts(animalWhereabouts);
      }
    });

    this.whereAboutTypeOptions = this._animalWhereaboutService.getAnimalWhereaboutTypeOptions();

    this.personSalutationOptions = this._helperService.getSalutationOptions();
  }

  setAnimalWhereabouts(animalWhereabouts: { [id: string]: AnimalWhereaboutModel }) {
    this.formGroupWhereabouts = {};

    Object.values(animalWhereabouts).forEach((animalWhereabout: AnimalWhereaboutModel) => {
      if (!animalWhereabout.id) {
        return;
      }
      this._contactRepository.getById(animalWhereabout.contact?.id).pipe(take(1)).subscribe((contact: ContactModel) => {
        this.animalWhereaboutsItems[animalWhereabout.id] = JSON.parse(JSON.stringify(animalWhereabout));
        this.contactInfosExist[animalWhereabout.id] = this._helperService.checkAnyContactData(contact);
        this.animalWhereaboutsItems[animalWhereabout.id].contact = contact;

        this.formGroupWhereabouts[animalWhereabout.id] = this.createFormGroup(this.animalWhereaboutsItems[animalWhereabout.id], this.isEditingMode);

        Promise.resolve().then(() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          const selectedTypeOption = this.whereAboutTypeOptions.find(option => option.value === animalWhereabout.type);
          this.formGroupWhereabouts[animalWhereabout.id].controls['type'].setValue(selectedTypeOption);
          this.cdr.detectChanges();
        });

        this.formGroupWhereabouts[animalWhereabout.id].valueChanges.subscribe(() => {
          this.allFormsValid = true;

          Object.values(this.formGroupWhereabouts).forEach((form: FormGroup) => {
            if (form.status === 'INVALID') {
              this.allFormsValid = false;
              return;
            }
          });
          this.formsValid.emit(this.allFormsValid);
        });
      });
    });
    this.cdr.detectChanges();
  }

  createFormGroup(animalWhereabout: any, isEditingMode: boolean): FormGroup {
    isEditingMode = animalWhereabout.id === 'dummy' ? true : isEditingMode;

    if (animalWhereabout.date) {
      animalWhereabout.date = new Date(animalWhereabout.date);
    }
    if (animalWhereabout.dateCare) {
      animalWhereabout.dateCare = new Date(animalWhereabout.dateCare);
    }

    return this.formBuilder.group({
      type: new FormControl({value: animalWhereabout.type, disabled: !isEditingMode}),
      date: new FormControl({value: animalWhereabout.date, disabled: !isEditingMode}),
      dateCare: new FormControl({value: animalWhereabout.dateCare, disabled: !isEditingMode}),
      information: new FormControl({value: animalWhereabout?.information, disabled: !isEditingMode}),
      stillOnWebsite: new FormControl({
        value: this.animal?.syncWithWebsite,
        disabled: (isEditingMode && !this.isAddMode)
      }),
      personSalutation: new FormControl({value: animalWhereabout.contact?.gender, disabled: !isEditingMode}),
      personLastName: new FormControl({value: animalWhereabout.contact?.lastName, disabled: !isEditingMode}),
      personFirstName: new FormControl({value: animalWhereabout.contact?.firstName, disabled: !isEditingMode}),
      personCompanyName: new FormControl({value: animalWhereabout.contact?.companyName, disabled: !isEditingMode}),
      personStreet: new FormControl({value: animalWhereabout.contact?.street, disabled: !isEditingMode}),
      personCity: new FormControl({value: animalWhereabout.contact?.city, disabled: !isEditingMode}),
      personZipCode: new FormControl({value: animalWhereabout.contact?.zipCode, disabled: !isEditingMode}),
      personPhoneNumber: new FormControl({value: animalWhereabout.contact?.phoneNumber, disabled: !isEditingMode}),
      personMobilePhoneNumber: new FormControl({
        value: animalWhereabout.contact?.mobilePhoneNumber,
        disabled: !isEditingMode
      }),
      personEmail: new FormControl({value: animalWhereabout.contact?.email, disabled: !isEditingMode}),
      personIdCardNumber: new FormControl({value: animalWhereabout.contact?.idCardNumber, disabled: !isEditingMode}),
    });
  }

  toggleEditDialog(open: boolean, whereAboutKey: string) {
    this.openEdit = open;
    this.isEditingMode = open;
    this.editingWhereAboutKey = whereAboutKey;
    if (open) {
      this.formGroupWhereabouts[whereAboutKey].enable();
    } else {
      this.formGroupWhereabouts[this.editingWhereAboutKey].disable();
    }
    this.cdr.detectChanges();
  }

  addNewWhereabout(): void {
    this.isAddMode = true;

    const emptyWhereabout = {
      id: 'dummy',
      animal: {id: this.animal?.id} as any,
      type: AnimalWhereaboutsType.ADOPTED,
      date: null,
      contact: {id: undefined},
      dateCare: null,
      information: null,
      personSalutation: null,
      personLastName: null,
      personFirstName: null,
      personCompanyName: null,
      personZipCode: null,
      personCity: null,
      personStreet: null,
      personPhoneNumber: null,
      personMobilePhoneNumber: null,
      personEmail: null,
      personIdCardNumber: null,
    };

    this.formGroupWhereabouts['dummy'] = this.createFormGroup(emptyWhereabout, this.isEditingMode);

    this.animalWhereaboutsItems['dummy'] = emptyWhereabout;
    this.newForm = true;
    this.cdr.detectChanges();
  }

  saveNewWhereabout() {
    const formValue = this.formGroupWhereabouts['dummy'].value;

    const payload: any = {
      type: formValue.type.value,
      date: formValue.date ? new Date(formValue.date) : null,
      dateCare: formValue.dateCare ? new Date(formValue.dateCare) : null,
      information: formValue.information,
      animal: {id: this.animal?.id} as any,
    };

    this._contactRepository.create({
      gender: formValue.personSalutation,
      firstName: formValue.personFirstName,
      lastName: formValue.personLastName,
      companyName: formValue.personCompanyName,
      zipCode: formValue.personZipCode,
      city: formValue.personCity,
      street: formValue.personStreet,
      phoneNumber: formValue.personPhoneNumber,
      mobilePhoneNumber: formValue.personMobilePhoneNumber,
      email: formValue.personEmail,
      idCardNumber: formValue.personIdCardNumber
    }).subscribe((response) => {
      const newContactId = response.id;
      if (newContactId) {
        payload.contact = {
          "id": newContactId
        };
      }
      this.createAnimalWhereabout(payload);
    });

    this.updateAnimal(formValue.stillOnWebsite);
  }

  updateAnimal(stillOnWebsite: boolean) {
    if (!this.animal || !this.animal.id) {
      return;
    }

    this._animalRepository.update(this.animal.id, {
      syncWithWebsite: stillOnWebsite
    });
  }

  createAnimalWhereabout(payload: any) {
    this._animalWhereaboutsRepository.create(payload).pipe(take(1)).subscribe((response) => {
      this.cancelNewWhereabout();
      this.animalWhereaboutsItems[response.id] = response;
      this.animalWhereaboutsItems[response.id].contact.id = payload.contact.id;
      this.setAnimalWhereabouts(this.animalWhereaboutsItems);
      this.isAddMode = false;
    });
  }

  cancelNewWhereabout() {
    this.isAddMode = false;
    delete this.animalWhereaboutsItems['dummy'];
    this.newForm = false;
    this.cdr.detectChanges();
  }

  dummyFirst = (a: any, b: any) => {
    return a.key === 'dummy' ? -1 : b.key === 'dummy' ? 1 : 0;
  }

  saveChanges() {
    const formValue = this.formGroupWhereabouts[this.editingWhereAboutKey].value;

    const contactPayload: any = {
      id: this.animalWhereaboutsItems[this.editingWhereAboutKey].contact?.id,
      gender: formValue.personSalutation,
      firstName: formValue.personFirstName,
      lastName: formValue.personLastName,
      companyName: formValue.personCompanyName,
      zipCode: formValue.personZipCode,
      city: formValue.personCity,
      street: formValue.personStreet,
      phoneNumber: formValue.personPhoneNumber,
      mobilePhoneNumber: formValue.personMobilePhoneNumber,
      email: formValue.personEmail,
      idCardNumber: formValue.personIdCardNumber
    };

    let date = null;
    if (formValue.date) {
      date = new Date(formValue.date);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    }

    let dateCare = null;
    if (formValue.dateCare) {
      dateCare = new Date(formValue.dateCare);
      dateCare.setMinutes(dateCare.getMinutes() - dateCare.getTimezoneOffset());
    }

    const payload = {
      type: formValue.type.value,
      information: formValue.information,
      date: date,
      dateCare: dateCare,
      contact: contactPayload
    };

    let newContactId = null;

    if (this.animalWhereaboutsItems[this.editingWhereAboutKey].contact?.id === null && (formValue.personSalutation
      || formValue.personFirstName
      || formValue.personLastName
      || formValue.personCompanyName
      || formValue.personZipCode
      || formValue.personCity
      || formValue.personStreet
      || formValue.personPhoneNumber
      || formValue.personMobilePhoneNumber
    )) {
      this._contactRepository.create(contactPayload).pipe(take(1)).subscribe((response) => {
        newContactId = response.id;
        contactPayload.id = newContactId;
        if (newContactId) {
          payload.contact = contactPayload;
        }
        this.editAnimalWhereabout(payload, response);
      });
    } else {
      if (this.editingWhereAboutKey && this.animalWhereaboutsItems[this.editingWhereAboutKey]) {
        if (contactPayload.id) {
          payload.contact = contactPayload;
          this._contactRepository.update(
            contactPayload,
            contactPayload.id
          ).pipe(take(1)).subscribe(() => {
            this.editAnimalWhereabout(payload, contactPayload);
          });
        }
      }
    }
  }

  editAnimalWhereabout(payload: any, contact: ContactModel) {
    this._animalWhereaboutsRepository.update(this.editingWhereAboutKey, payload).pipe(take(1)).subscribe((response) => {
      this.toggleEditDialog(false, this.editingWhereAboutKey);
      this.animalWhereaboutsItems[response.id] = response;
      this.animalWhereaboutsItems[response.id].contact = contact;
      this.setAnimalWhereabouts(this.animalWhereaboutsItems);
    });
  }

  dummyTranslateFunction() {
    this._translocoService.translate('animalWhereabout.died');
    this._translocoService.translate('animalWhereabout.adopted');
    this._translocoService.translate('animalWhereabout.back-with-owner');
    this._translocoService.translate('animalWhereabout.passed-on');
    this._translocoService.translate('animalWhereabout.ran-away');
    this._translocoService.translate('animalWhereabout.released-into-wild');
    this._translocoService.translate('animalWhereabout.adopted-on-trial');
    this._translocoService.translate('animalWhereabout.outdoor-animal');
    this._translocoService.translate('animalWhereabout.foster-home');
    this._translocoService.translate('animalWhereabout.euthanasia');
    this._translocoService.translate('animalWhereabout.conveyed');
  }


  closeEditModal() {
    throw new Error('Method not implemented.');
  }

}
